// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaSlide_QjkkN{display:block;height:100%;position:relative;transform:translateZ(0);width:100%}.MediaSlide_QjkkN._shadows_JT7VN:after{background:linear-gradient(180deg,#1110 53.5%,#11111185),linear-gradient(180deg,#111,#1110 21%),linear-gradient(180deg,#11111152,#1110 18%),linear-gradient(114deg,#0000001f 64%,#0000001f 90%);bottom:0;content:\"\";left:0;position:absolute;right:0;top:0}.MediaSlide_QjkkN._contain_8eSvG>img.image_hIXiO,.MediaSlide_QjkkN._contain_8eSvG>video.image_hIXiO{object-fit:contain}img.image_hIXiO,video.image_hIXiO{display:block;height:100%;object-fit:cover;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"MediaSlide": "MediaSlide_QjkkN",
	"_shadows": "_shadows_JT7VN",
	"_contain": "_contain_8eSvG",
	"image": "image_hIXiO"
};
module.exports = ___CSS_LOADER_EXPORT___;
