
export default {
    name: 'VSwiperPaginationNew',
    props: {
        slidesCount: {
            type: Number,
            default: 0,
        },

        activeIndex: {
            type: Number,
            default: 0,
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => [
                'medium',
            ].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: value => [
                'base',
                'dark',
            ].includes(value),
        },

        /**
         * Модификатор вида с отслеживанием прогресса автоплея
         */
        withProgress: {
            type: Boolean,
            default: false,
        },

        /**
         * Прогресса автоплея слайдера от 0 до 1
         */
        autoplayProgress: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style[`_${this.color}`]]: this.color,
                    [this.$style[`_${this.size}`]]: this.size,
                    [this.$style._withProgress]: this.withProgress,
                },
            ];
        },
    },
};
