// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VSwiperPaginationNew_ibfqb{align-items:center;display:flex;justify-content:center}.VSwiperPaginationNew_ibfqb._base_ZzBin .bullet_iACL4{background-color:#c0a6ff}.VSwiperPaginationNew_ibfqb._base_ZzBin .bullet_iACL4._active_rlm3O:after{background-color:#9971ff}.VSwiperPaginationNew_ibfqb._dark_4J1bM .bullet_iACL4{background-color:#fff6}.VSwiperPaginationNew_ibfqb._dark_4J1bM .bullet_iACL4._active_rlm3O:after{background-color:#fff}.VSwiperPaginationNew_ibfqb._medium_pVZqn .bullet_iACL4{height:.4rem;width:.4rem}.VSwiperPaginationNew_ibfqb._medium_pVZqn .bullet_iACL4:not(.VSwiperPaginationNew_ibfqb._medium_pVZqn .bullet_iACL4:last-child){margin-right:.8rem}.VSwiperPaginationNew_ibfqb._medium_pVZqn._withProgress_EaV03 .bullet_iACL4._active_rlm3O{width:2.4rem}.VSwiperPaginationNew_ibfqb._withProgress_EaV03 .bullet_iACL4:after{transform:scaleX(var(--progress));transform-origin:left}.bullet_iACL4{position:relative;transition:width .3s ease}.bullet_iACL4:after{background-color:#0000;content:\"\";height:100%;left:0;position:absolute;top:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VSwiperPaginationNew": "VSwiperPaginationNew_ibfqb",
	"_base": "_base_ZzBin",
	"bullet": "bullet_iACL4",
	"_active": "_active_rlm3O",
	"_dark": "_dark_4J1bM",
	"_medium": "_medium_pVZqn",
	"_withProgress": "_withProgress_EaV03"
};
module.exports = ___CSS_LOADER_EXPORT___;
