import LazyLoad from 'vanilla-lazyload';

// Constants
const LAZY_OPTIONS = {
    threshold: 0,
    use_native: false,
    unobserve_completed: true,
    unobserve_entered: true,
    elements_selector: '.lazy',
};

export const lazyLoadSlider = {
    data() {
        return {
            lazyLoad: null, // Инстанс vanilla-lazyload
            firstLoad: false, // autoplay срабатывает только после загрузки первого изображения
        };
    },

    methods: {
        /**
         * Отключает autoplay и инициирует vanilla-lazyLoad.
         * После подмены байтового изображения, подгружает превью,
         * затем в качестве коллбэка вызывает метод handleSecondLoad
         * @param {Object} swiper instance
         * @public
         */
        handleInitLazyLoad(swiper) {
            if (!this.$refs?.slider) {
                return;
            }

            if (swiper?.params?.autoplay?.enabled) {
                swiper.autoplay.stop();
            }

            this.lazyLoad = new LazyLoad({
                ...LAZY_OPTIONS,
                callback_loaded: this.handleSecondLoad,
                container: this.$refs.slider,
            });
        },

        /**
         * Создаёт новый инстанс vanilla-lazyLoad,
         * чтобы заменить превью на полноценное изображение.
         * Также запускает swiper autoplay в коллбэке,
         * после первой, полноценной, загрузки изображения
         * @param {Element} el html элемент слайда
         * @public
         */
        handleSecondLoad(el) {
            const lazyOptions = {
                ...LAZY_OPTIONS,
                data_srcset: 'lazy-srcset',
                data_sizes: 'lazy-sizes',
                callback_loaded: () => {
                    if (!this.firstLoad) {
                        if (this.swiper?.params?.autoplay?.enabled) {
                            this.swiper.autoplay.start();
                        }
                        this.firstLoad = true;
                    }
                },
            };

            ['data-ll-status', 'data-src'].forEach(attribute => el.removeAttribute(attribute));
            el.instance = new LazyLoad(lazyOptions, el.item);
        },
    },
};
